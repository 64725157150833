import { Form, Select } from 'antd';
import { jwtDecode } from 'jwt-decode';
import React, { useEffect, useState } from 'react';
import '../../../src/style.css';
import { Axios } from '../../config';
import Header from '../Header/Header';
import Logged from '../Header/Logged';

import { useAppStore } from '../../store';
import LoadAnim from '../LoadAnim';

import '../../../src/style_tailwind.css';

const { Option } = Select;

function Codes() {
  const loading = useAppStore.use.loading();
  const updateLoading = useAppStore.use.updateLoading();
  const [ansi, setAnsi] = useState([]);
  const [searchcol, setSearchcol] = useState('');
  const [ansilist, setAnsilist] = useState('');
  const [iec61850list, setIec61850list] = useState('');

  useEffect(() => {
    updateLoading(false);
  });

  useEffect(
    (notyf) => {
      Axios.get('/api/get_ansi').then((response) => {
        setAnsi(response.data);
      });
      updateLoading(true);
    },
    [updateLoading]
  );

  const decoded = jwtDecode(sessionStorage.token);
  const access = parseInt(decoded.result[0].access);

  const handleChangeCol = (value) => {
    setSearchcol(value);
  };

  const onSearch = (value) => {
    setAnsilist(value);
    setIec61850list(value);
    form.resetFields();
  };

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const [form] = Form.useForm();

  return (
    <main className="overflow-hidden mt-36 place-items-center">
      {access > 0 ? <Logged /> : <Header />}
      <Form {...layout} name="control-ref" form={form}>
        <div className="flex flex-col m-2 border-2 max-w-6xl border-blue-spie bg-gray-200">
          <div className="flex flex-row bg-blue-spie m-2">
            <div className="flex w-full flex-col justify-start bg-white p-2">
              <table className="table-fixed border border-blue-spie">
                <caption className="caption-top bg-blue-spie flex p-2">
                  <h4 className="text-white text-base">
                    <b>LISTE DES CODES</b>
                  </h4>
                </caption>
                {loading === true ? (
                  <LoadAnim size="large" />
                ) : (
                  <div className="flex m-1 p-2">
                    <Form.Item
                      name="tri"
                      label="Option de tri"
                      style={{
                        margin: '0.5rem',
                      }}
                    >
                      <Select
                        placeholder="Select By:"
                        onChange={handleChangeCol}
                        style={{ width: 250 }}
                      >
                        <Option value="ansi">ANSI</Option>
                        <Option value="iec61850">IEC61850</Option>
                        <Option value="tous">TOUS...</Option>
                      </Select>
                    </Form.Item>
                    <>
                      {searchcol === 'ansi' ? (
                        <Form.Item
                          name="ansi"
                          style={{
                            margin: '0.5rem',
                            marginLeft: '2rem',
                          }}
                        >
                          <Select
                            showSearch
                            placeholder="Select a code"
                            onChange={onSearch}
                            style={{ width: 200 }}
                          >
                            {ansi.map((val, index) => (
                              <Select.Option key={index} value={val.ansi}>
                                {val.ansi}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        <></>
                      )}
                      {searchcol === 'iec61850' ? (
                        <Form.Item
                          name="iec61850"
                          style={{
                            margin: '0.5rem',
                            marginLeft: '2rem',
                          }}
                        >
                          <Select
                            showSearch
                            placeholder="Select a code"
                            onChange={onSearch}
                            style={{ width: 200 }}
                          >
                            {ansi.map((val, index) => (
                              <Select.Option key={index} value={val.iec61850}>
                                {val.iec61850}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      ) : (
                        <></>
                      )}
                    </>
                  </div>
                )}
                <div className="place-items-center">
                  <thead>
                    <tr>
                      <th className="w-32 text-blue-spie text-base">ANSI</th>
                      <th className="w-32 text-blue-spie text-base">
                        IEC61850
                      </th>
                      <th className="w-32 text-blue-spie text-base">
                        IEC60617
                      </th>
                      <th className="w-80 text-blue-spie text-base">
                        DESIGNATION FR
                      </th>
                      <th className="w-80 text-blue-spie text-base">
                        DESIGNATION EN
                      </th>
                    </tr>
                  </thead>
                  {ansi
                    .filter((val) => {
                      if (searchcol === 'ansi' && ansilist.includes(val.ansi))
                        if (ansilist !== '') {
                          return val;
                        }
                      if (
                        searchcol === 'iec61850' &&
                        iec61850list.includes(val.iec61850)
                      )
                        if (iec61850list !== '') {
                          return val;
                        }
                      if (searchcol === 'tous') {
                        return val;
                      }
                      return false;
                    })
                    .map((val, index) => {
                      return (
                        <tbody className="" key={index}>
                          <tr className="border-t-2">
                            <td className="w-32 text-center">{val.ansi}</td>
                            <td className="w-32 text-center">{val.iec61850}</td>
                            <td className="w-32 text-center">{val.iec60617}</td>
                            <td className="w-80 text-center">{val.designFr}</td>
                            <td className="w-80 text-center">{val.designEn}</td>
                          </tr>
                        </tbody>
                      );
                    })}
                </div>
              </table>
            </div>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default Codes;
