import React, { useEffect } from 'react';
import image from '../../../src/Komsilga.jpg';

import '../../../src/style.css';
import '../../../src/utilities.css';

export default function Home() {
  useEffect(() => {
    sessionStorage.setItem(
      'token',
      'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXN1bHQiOlt7ImlkIjoxLCJsYXN0bmFtZSI6IngiLCJmaXJzdG5hbWUiOiJ4IiwiZW1haWwiOiJ4QHNwaWUuY29tIiwicGFzc3dvcmQiOiIiLCJhY2Nlc3MiOjAsImNyZWF0ZV90aW1lIjoiMjAyNC0xMS0wMVQwMDowMDowOC4wMDBaIn1dLCJpYXQiOjE3MzA0MTkyMDAsImV4cCI6MjUyNDYwODAwMH0.z5mT7sEZOzqQl81ok8NJCRFs2xprrf7-2us_QWi8F8U'
    );
    sessionStorage.setItem('auth', false);
  }, []);

  return (
    <main>
      {/* <Header /> */}
      <img src={image} width="100%" alt="Centrâle de Komsilga Burkina Faso" />
    </main>
  );
}
