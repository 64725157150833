import { Space, Spin } from 'antd';
import React from 'react';
import '../style.css';

export default function LoadAnim(props) {
  return (
    <Space direction="vertical">
      <div className="index">
        <Spin tip="Loading" size={props.size}></Spin>
      </div>
    </Space>
  );
}
