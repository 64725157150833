import { Button, Form, Input, Select } from 'antd';
import React from 'react';

export default function CardAddMdp({
  onFinish,
  onFinishFailed,
  onNumChange,
  company,
  setButtonPopup,
}) {
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <Form
      {...layout}
      name="AddMdp"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <main className="flex justify-center justify-items-center absolute w-full  h-screen top-0 left-0">
        <div className="relative max-w-6xl border border-white">
          <div className="m-2 border bg-blue-spie">
            <h4 className="text-white text-base text-center mt-2">
              AJOUTER UN ELEMENT
            </h4>
            <div className="flex flex-col  bg-white m-1">
              <table>
                <thead>
                  <tr>
                    <th>
                      <h3>Marque</h3>
                    </th>
                    <th>
                      <h3>Appareil</h3>
                    </th>
                    <th>
                      <h3>Type</h3>
                    </th>
                    <th>
                      <h3>Utilisateur</h3>
                    </th>
                    <th>
                      <h3>Mot de Passe</h3>
                    </th>
                    <th>
                      <h3>Site</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Item
                        name="marque"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          placeholder="Marque / Fourn."
                          onChange={onNumChange}
                        >
                          {company.map((val, index) => (
                            <Select.Option key={index} value={val.num}>
                              {val.num}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="appareil"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="appareil" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="type"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="type" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="iduser"
                        style={{
                          margin: '0.5rem',
                        }}
                      >
                        <Input type="text" maxLength={20} name="iduser" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="passwordadd"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="passwordadd" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="site"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="site" />
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Form.Item
                style={{
                  margin: '0.5rem',
                }}
              >
                <Button
                  style={{ backgroundColor: '#0f1e5a' }}
                  type="primary"
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
              <div className="absolute top-3 right-3">
                <Form.Item
                  style={{
                    backgroundColor: '',
                  }}
                >
                  <Button
                    style={{ backgroundColor: 'white' }}
                    htmlType="button"
                    size="small"
                    onClick={onReset}
                  >
                    X
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Form>
  );
}
