import { Button, Form, Input, Select } from 'antd';
import React from 'react';
import '../../../node_modules/antd/dist/antd.min.css';
import '../../../src/style.css';
import '../../../src/style_tailwind.css';

import { MailOutlined, UserOutlined } from '@ant-design/icons';

const { Option } = Select;

export default function CardAjoutUtil({
  onFinish,
  onFinishFailed,
  setButtonPopup,
  handleChangeCol,
}) {
  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <Form
      name="basic"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <main className="flex justify-center justify-items-center absolute w-full  h-screen top-1 left-0">
        <div className="relative max-w-6xl">
          <div className="m-2 border bg-blue-spie">
            <h4 className="flex text-white text-base text-center mt-2 w-96">
              AJOUTER UN UTILISATEUR
            </h4>
            <div className="flex flex-col bg-white m-1 p-2">
              <Form.Item
                label="Nom"
                name="lastname"
                rules={[{ required: true }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Last Name"
                  value="Last Name"
                  maxLength={20}
                ></Input>
              </Form.Item>
              <Form.Item
                label="Prénom"
                name="firstname"
                rules={[{ required: true }]}
              >
                <Input
                  placeholder="First Name"
                  value="First Name"
                  maxLength={20}
                ></Input>
              </Form.Item>
              <Form.Item
                label="Courriel Spie"
                name="email"
                rules={[{ required: true }, { type: 'email' }]}
              >
                <Input
                  prefix={<MailOutlined className="site-form-item-icon" />}
                  placeholder="Email"
                  value="Email"
                  maxLength={45}
                ></Input>
              </Form.Item>
              <Form.Item
                label="Mot de passe"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                name="access"
                label="Niveau"
                style={{
                  margin: '0.5rem',
                }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a level"
                  onChange={handleChangeCol}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <Option value="0">Accès limité</Option>
                  <Option value="1">Utilisateurs Serres Castet</Option>
                  <Option value="2">Utilisateurs Autres</Option>
                  <Option value="3">Tech. Mise en service</Option>
                  <Option value="4">Encadrement</Option>
                  <Option value="5">Administrateur</Option>
                </Select>
              </Form.Item>
              <div className="flex justify-center justify-items-center">
                <Form.Item
                  style={{
                    margin: 1,
                  }}
                >
                  <Button
                    style={{ backgroundColor: '#0f1e5a' }}
                    type="primary"
                    htmlType="submit"
                  >
                    Ajouter
                  </Button>
                </Form.Item>
                <Form.Item
                  style={{
                    margin: 1,
                  }}
                >
                  <Button
                    style={{ backgroundColor: '#0f1e5a' }}
                    htmlType="button"
                    type="primary"
                    onClick={onReset}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </div>
              <div className="absolute top-3 right-3">
                <Form.Item>
                  <Button
                    style={{ backgroundColor: 'white' }}
                    htmlType="button"
                    size="small"
                    onClick={onReset}
                  >
                    X
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Form>
  );
}
