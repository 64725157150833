import React from 'react';

import { Button, Form, Input, Select, Space } from 'antd';

import moment from 'moment';

const { Search } = Input;
const { Option } = Select;

export default function CardGestion({
  loading,
  LoadAnim,
  searchcol,
  searchspie,
  searchsn,
  onSearch,
  supplier,
  user,
  onSearchsn,
  access,
  idlist,
  btInfo,
  btStock,
  btUpdate,
  btDelete,
  handleChangeCol,
  valitem,
  btAjout,
  designation,
}) {
  const idlistt = idlist || [];
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const [form] = Form.useForm();

  return (
    <Form {...layout} name="control-ref" form={form}>
      <div className="flex flex-col m-2 border-2 max-w-6xl border-blue-spie bg-gray-200">
        <div className="flex m-2 border-2">
          <div className="flex flex-col justify-start bg-white p-2">
            <table className="table-fixed border border-blue-spie">
              <caption className="caption-top  bg-blue-spie flex p-2">
                <h4 className="text-white text-base">
                  <b>GESTION DE L'OUTILLAGE</b>
                </h4>
              </caption>
              {loading === true ? (
                <LoadAnim size="large" />
              ) : (
                <div className="flex m-1 p-2">
                  <Form.Item
                    name="tri"
                    label="Sélectionner une option de tri"
                    style={{
                      margin: '0.5rem',
                    }}
                  >
                    <Select
                      placeholder="Select by:"
                      onChange={handleChangeCol}
                      style={{ width: 250, marginRight: 10 }}
                    >
                      <Option value="spie">N° ID SPIE</Option>
                      <Option value="num">FABRICANT</Option>
                      <Option value="design">DESIGNATION</Option>
                      <Option value="userid">ATTRIBUE A</Option>
                      <Option value="sn">NUM. de SERIE</Option>
                    </Select>
                  </Form.Item>
                  <>
                    {searchcol === 'spie' ? (
                      <Form.Item
                        name="spie"
                        style={{
                          margin: '0.5rem',
                        }}
                      >
                        <Search
                          placeholder="input search"
                          icon="search"
                          size="middle"
                          onSearch={onSearch}
                          style={{ width: 250 }}
                        />
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                    {searchcol === 'num' ? (
                      <Form.Item
                        name="num"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          placeholder="Select a factory"
                          onChange={onSearch}
                          style={{ width: 200, marginRight: 10 }}
                        >
                          {supplier.map((val, index) => (
                            <Select.Option
                              key={index}
                              value={val.supplier_name}
                            >
                              {val.supplier_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                    {searchcol === 'userid' ? (
                      <Form.Item
                        name="userid"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          placeholder="Select a name"
                          onChange={onSearch}
                          style={{ width: 200, marginRight: 10 }}
                        >
                          {user.map((val, index) => (
                            <Select.Option key={index} value={val.lastname}>
                              {val.lastname}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                    {searchcol === 'design' ? (
                      <Form.Item
                        name="design"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Select
                          showSearch
                          placeholder="Select une désignation"
                          onChange={onSearch}
                          style={{ width: 200, marginRight: 10 }}
                        >
                          {designation.map((val, index) => (
                            <Select.Option key={index} value={val.design}>
                              {val.design}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                    {searchcol === 'sn' ? (
                      <Form.Item
                        name="sn"
                        style={{
                          margin: '0.5rem',
                        }}
                      >
                        <Search
                          placeholder="input search"
                          icon="search"
                          size="middle"
                          onSearch={onSearchsn}
                          style={{ width: 250 }}
                        />
                      </Form.Item>
                    ) : (
                      <></>
                    )}
                  </>
                  {access > 3 ? (
                    <Space wrap>
                      <Button
                        onClick={() => {
                          btAjout(valitem);
                        }}
                      >
                        Ajout outils
                      </Button>
                    </Space>
                  ) : (
                    <></>
                  )}
                </div>
              )}
              <div className="place-items-center">
                <thead className="">
                  <tr>
                    {access === 6 ? (
                      <>
                        <th className="w-10 text-blue-spie text-base">ID</th>
                      </>
                    ) : (
                      <></>
                    )}
                    <th className="w-32 text-blue-spie text-base">N°</th>
                    <th className="w-40 text-blue-spie text-base">MARQUE</th>
                    <th className="w-36 text-blue-spie text-base">TYPE</th>
                    <th className="w-40 text-blue-spie text-base">
                      NUM. SERIE
                    </th>
                    <th className="w-28 text-blue-spie text-base">VALIDE</th>
                    <th className="w-28 text-blue-spie text-base">
                      ATTRIBUE A
                    </th>
                    <th className="w-64 text-blue-spie text-base">ACTION</th>
                  </tr>
                </thead>
                {/* Tableau final */}
                {idlistt
                  .filter((val) => {
                    if (
                      searchcol === 'spie' &&
                      val.spie
                        .toLocaleLowerCase()
                        .includes(searchspie.toLocaleLowerCase())
                    ) {
                      if (searchspie !== '') {
                        return val;
                      }
                    } else if (
                      searchcol === 'num' &&
                      val.num
                        .toLocaleLowerCase()
                        .includes(searchspie.toLocaleLowerCase())
                    ) {
                      if (searchspie !== '') {
                        return val;
                      }
                    } else if (
                      searchcol === 'design' &&
                      val.design
                        .toLocaleLowerCase()
                        .includes(searchspie.toLocaleLowerCase())
                    ) {
                      if (searchspie !== '') {
                        return val;
                      }
                    } else if (
                      searchcol === 'userid' &&
                      val.userid
                        .toLocaleLowerCase()
                        .includes(searchspie.toLocaleLowerCase())
                    ) {
                      if (searchspie !== '') {
                        return val;
                      }
                    } else if (
                      searchcol === 'sn' &&
                      val.sn
                        .toLocaleLowerCase()
                        .includes(searchsn.toLocaleLowerCase())
                    ) {
                      if (searchsn !== '') {
                        return val;
                      }
                    }
                    return false;
                  })
                  .sort((a, b) => (a.spie > b.spie ? 1 : -1))
                  .map((val, index) => {
                    var now = moment();
                    var nowAlarm = moment().add(90, 'd');
                    var valid = val.rev;
                    var dateLimit = moment(valid);

                    return (
                      <tbody className="align-middle" key={index}>
                        <tr className="border-t-2">
                          {access === 6 ? (
                            <>
                              <td className="w-10">{val.id}</td>
                            </>
                          ) : (
                            <></>
                          )}
                          <td className="w-28">{val.spie}</td>{' '}
                          {/* Numéro Spie */}
                          <td className="w-40">{val.num}</td> {/* Marque */}
                          <td className="w-36">{val.ref}</td> {/* type */}
                          <td className="w-40">{val.sn}</td> {/* N° de série */}
                          <td className="w-28 place-items-center">
                            {dateLimit <= now ? (
                              <div className="w-20 limitAlert px-1">
                                {dateLimit.format('DD-MM-YYYY')}
                              </div>
                            ) : (
                              <></>
                            )}
                            {dateLimit >= now && dateLimit <= nowAlarm ? (
                              <div className="w-20 limitAlarm px-1">
                                {dateLimit.format('DD-MM-YYYY')}
                              </div>
                            ) : (
                              <></>
                            )}
                            {dateLimit >= nowAlarm ? (
                              <div className="w-20 limit px-1">
                                {dateLimit.format('DD-MM-YYYY')}
                              </div>
                            ) : (
                              <></>
                            )}
                          </td>
                          <td className="w-20 text-center">
                            {val.userid === 'HORS-ST' ||
                            val.userid === 'NON AFFECTE' ? (
                              <div className="limitAlert px-1">
                                {val.userid}
                              </div>
                            ) : (
                              <div className="limit">{val.userid}</div>
                            )}
                          </td>
                          <td className="w-64">
                            <Space wrap>
                              <Button
                                onClick={() => {
                                  btInfo(val);
                                }}
                              >
                                Info
                              </Button>
                              {access > 0 ? (
                                <>
                                  <Button
                                    type="primary"
                                    size="small"
                                    ghost
                                    onClick={() => {
                                      btStock(val);
                                    }}
                                  >
                                    Stock
                                  </Button>
                                </>
                              ) : (
                                <></>
                              )}
                              {access > 3 ? (
                                <>
                                  <Button
                                    type="primary"
                                    size="small"
                                    ghost
                                    onClick={() => {
                                      btUpdate(val);
                                    }}
                                  >
                                    Update
                                  </Button>
                                </>
                              ) : (
                                <></>
                              )}
                              {access > 5 ? (
                                <>
                                  <Button
                                    type="primary"
                                    size="small"
                                    danger
                                    ghost
                                    onClick={() => {
                                      btDelete(val);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </>
                              ) : (
                                <></>
                              )}
                            </Space>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </div>
            </table>
          </div>
        </div>
      </div>
    </Form>
  );
}
