import { Button, Form, Select } from 'antd';
import React from 'react';
import '../../../node_modules/antd/dist/antd.min.css';
import '../../../src/style.css';
import '../../../src/style_tailwind.css';

const { Option } = Select;

export default function CardNivAccess({
  onFinish,
  onFinishFailed,
  user,
  setButtonPopup,
  handleChangeCol,
  onSearch,
}) {
  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <Form
      name="reinitmdp"
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <main className="flex justify-center justify-items-center absolute w-full  h-screen top-0 left-0">
        <div className="relative max-w-6xl">
          <div className="m-2 border bg-blue-spie">
            <h4 className="text-white text-base text-center mt-2">
              Changer le Niveau accés
            </h4>
            <div className="flex flex-col  bg-white m-1">
              <Form.Item
                name="userid"
                label="Utilisateur"
                style={{
                  margin: '0.5rem',
                }}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select a name"
                  onChange={onSearch}
                  style={{ width: 200, marginRight: 10 }}
                >
                  {user.map((val, index) => (
                    <Select.Option key={index} value={val.id}>
                      {val.lastname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                name="access"
                label="Niveau"
                style={{
                  margin: '0.5rem',
                }}
                rules={[{ required: true }]}
              >
                <Select
                  placeholder="Select a level"
                  onChange={handleChangeCol}
                  style={{ width: 200, marginRight: 10 }}
                >
                  <Option value="0">Accès limité</Option>
                  <Option value="1">Utilisateurs Serres Castet</Option>
                  <Option value="2">Utilisateurs Autres</Option>
                  <Option value="3">Tech. Mise en service</Option>
                  <Option value="4">Encadrement</Option>
                  <Option value="5">Administrateur</Option>
                </Select>
              </Form.Item>
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 16,
                }}
              >
                <Button
                  style={{ backgroundColor: '#0f1e5a' }}
                  type="primary"
                  htmlType="submit"
                >
                  Valider
                </Button>
              </Form.Item>
            </div>
            <div className="absolute top-3 right-3">
              <Form.Item>
                <Button
                  style={{ backgroundColor: 'white' }}
                  htmlType="button"
                  size="small"
                  onClick={onReset}
                >
                  X
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </main>
    </Form>
  );
}
