import { Button, DatePicker, Form, Input, Select } from 'antd';
import React from 'react';

export default function CardAdd({
  onFinish,
  onFinishFailed,
  supplier,
  user,
  setButtonPopup,
}) {
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 32,
    },
  };

  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <Form
      {...layout}
      name="Add"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <main className="popup">
        <div className="popup-inner">
          <div className="card01">
            <h4>AJOUTER UN ELEMENT</h4>
            <div className="card1 flex">
              <table>
                <thead>
                  <tr>
                    <th>
                      <h3>N° Spie</h3>
                    </th>
                    <th>
                      <h3>Marque</h3>
                    </th>
                    <th>
                      <h3>Type</h3>
                    </th>
                    <th>
                      <h3>Désignation</h3>
                    </th>
                    <th>
                      <h3>Commentaire</h3>
                    </th>
                    <th>
                      <h3>N.Série</h3>
                    </th>
                    <th>
                      <h3>Validité</h3>
                    </th>
                    <th>
                      <h3>Attribué à:</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Form.Item
                        name="spie"
                        style={{
                          margin: '1rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input placeholder="N° Spie" maxLength={20} />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="num"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Select showSearch placeholder="Marque / Fourn.">
                          {supplier.map((val, index) => (
                            <Select.Option
                              key={index}
                              value={val.supplier_name}
                            >
                              {val.supplier_name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="ref"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="ref" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="design"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Input type="text" name="design" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="obs"
                        style={{
                          margin: '0.5rem',
                        }}
                      >
                        <Input type="text" maxLength={20} name="obs" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="sn"
                        style={{
                          margin: '0.5rem',
                        }}
                      >
                        <Input type="text" name="sn" />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="rev"
                        style={{
                          margin: '0.5rem',
                        }}
                      >
                        <DatePicker
                          format={dateFormatList}
                          type="text"
                          name="rev"
                        />
                      </Form.Item>
                    </td>
                    <td>
                      <Form.Item
                        name="userid"
                        style={{
                          margin: '0.5rem',
                        }}
                        rules={[{ required: true }]}
                      >
                        <Select showSearch placeholder="Attribué à:">
                          {user.map((val, index) => (
                            <Select.Option key={index} value={val.id}>
                              {val.lastname}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Form.Item
                style={{
                  margin: '0.5rem',
                }}
              >
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
              <div className="close-btn">
                <Form.Item>
                  <Button htmlType="button" size="small" onClick={onReset}>
                    X
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Form>
  );
}
