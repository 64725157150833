import { Button, DatePicker, Form, Select, Space } from 'antd';
import React from 'react';
import '../../../node_modules/antd/dist/antd.min.css';
import '../../../src/Popup.css';
import '../../../src/style.css';
import '../../../src/style_tailwind.css';

export default function CardTraining({
  onReset,
  onFinishFailed,
  onFinish,
  typeform,
  setTypeForm,
  client,
  userslist,
  username,
  access,
  setIdUsername,
}) {
  const [form] = Form.useForm();
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  const handleType = (values) => {
    console.log('handletype', values);
    setTypeForm(values);
  };

  const onSearch = (value) => {
    setIdUsername(value);
  };
  const { Option } = Select;
  const clientslist = client.sort((a, b) =>
    a.nom + a.lieu > b.nom + b.lieu ? 1 : -1
  );
  const user = userslist.sort((a, b) => (a.lastname > b.lastname ? 1 : -1));
  const habil = [
    'B0',
    'H0V',
    'BR',
    'BEVérif',
    'HEVérif',
    'BC',
    'HC',
    'B1V',
    'H1V',
    'B2VEssai',
    'H2VEssai',
  ];

  return (
    <Form
      name="basic"
      style={{
        width: 280,
      }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <main className="flex justify-center justify-items-center absolute w-full  h-screen top-1 left-0">
        <div className="relative max-w-6xl">
          <div className="m-2 border bg-blue-spie">
            <h4 className="text-white text-base text-center mt-2 w-96">
              AJOUTER UNE FORMATION
            </h4>
            <div className="flex flex-col bg-white m-1 p-2">
              <Form.Item label="Type" name="type">
                <Select
                  placeholder="Sélectionner une option"
                  style={{
                    width: 300,
                    marginTop: '0.5rem',
                  }}
                  onChange={handleType}
                >
                  <Option value="Habilitation Electrique">
                    Habilitation Electrique (Opérations d'ordre Non Electrique
                    et Electrique)
                  </Option>
                  <Option value="Formation">
                    Autorisations, Formations (Travaux en hauteurs & levage,
                    Risque Chimique - Atex - Jointage - ARI, Clients et Divers)
                  </Option>
                  <Option value="Spécifique site">
                    Formation Spécifique Site
                  </Option>
                  <Option value="Autorisation de conduite">
                    Autorisation de conduite
                  </Option>
                  <Option value="Attestation Santé">Attestation Santé</Option>
                  <Option value="Visa Multiple">Visa Multiple</Option>
                  <Option value="Visa Unique">Visa Unique</Option>
                  <Option value="Offshore">OffShore</Option>
                </Select>
              </Form.Item>
              {access > 4 ? (
                <Form.Item
                  label="Utilisateur"
                  name="userid"
                  style={{
                    width: 300,
                  }}
                >
                  <Select
                    showSearch
                    placeholder={username}
                    onChange={onSearch}
                    style={{ width: 200 }}
                  >
                    {user.map((val, index) => (
                      <Select.Option key={index} value={val.id}>
                        {val.lastname}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              ) : (
                <></>
              )}

              {typeform === 'Habilitation Electrique' ? (
                <Form.Item
                  label="Titre"
                  name="data1"
                  hasFeedback
                  style={{
                    width: 300,
                  }}
                >
                  <Select mode="multiple" placeholder="Select" allowClear>
                    {habil.map((habil, index) => {
                      return (
                        <Select.Option key={index} value={habil}>
                          {habil}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              ) : (
                <></>
              )}
              {typeform === 'Formation' ? (
                <Form.Item
                  label="Titre"
                  name="data1"
                  hasFeedback
                  style={{
                    width: 300,
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a title"
                    style={{
                      width: 260,
                    }}
                  >
                    <Option value="Risque chimique N1">
                      Risque chimique N 1 (3ans)
                    </Option>
                    <Option value="Risque chimique N2">
                      Risque chimique N 2 (4ans)
                    </Option>
                    <Option value="NF C18-510">
                      NF C 18-510 UTE C 18-510 NF C 18-550
                    </Option>
                    <Option value="Travaux sur toiture pente et terrasse">
                      Travaux sur toiture pente et terrasse
                    </Option>
                    <Option value="AIPR - Opérateur Réseaux aériens">
                      AIPR - Opérateur Réseaux aériens
                    </Option>
                    <Option value="TST Batteries NF C18-505 TST IEBAT">
                      TST Batteries NF C18-505 TST IEBAT
                    </Option>
                    <Option value="Executant non mecanicien M0">
                      Exécutant non mécanicien M0
                    </Option>
                    <Option value="Echaffaudage roulant Vérification Utilisation R457">
                      Echaffaudage roulant Vérification Utilisation R457
                    </Option>
                    <Option value="Echaffaudage de pied Vérification Utilisation R408">
                      Echaffaudage de pied Vérification Utilisation R408
                    </Option>
                    <Option value="Chargé de Travaux - SEE Niveau 2 UIC DT40">
                      Chargé de Travaux - SEE Niveau 2 UIC DT40
                    </Option>
                  </Select>
                </Form.Item>
              ) : (
                <></>
              )}
              {typeform === 'Autorisation de conduite' ? (
                <Form.Item
                  label="Titre"
                  name="data1"
                  hasFeedback
                  style={{
                    width: 300,
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a title"
                    style={{
                      width: 260,
                    }}
                  >
                    <Option value="PEMP Groupes A et B type 1 et 3 R.486">
                      PEMP Groupes A et B type 1 et 3 R.486
                    </Option>
                    <Option value="PEMP type 3A et 3B R.386">
                      PEMP type 3A et 3B R.386
                    </Option>
                    <Option value="Chariot de manutention tout terrain R.482">
                      Chariot de manutention tout terrain R.482
                    </Option>
                    <Option value="Chariot élévateur en porte à faux R.489">
                      Chariot élévateur en porte à faux R.489
                    </Option>
                    <Option value="Engin de charg. à déplac. alternatif R.372 Cat.4">
                      Engin de chargment à déplacement alternatif R.372 Cat.4
                    </Option>
                    <Option value="Engin de manutention R.372 Cat.9">
                      Engin de manutention R.372 Cat.9
                    </Option>
                  </Select>
                </Form.Item>
              ) : (
                <></>
              )}
              {typeform === 'Attestation Santé' ? (
                <Form.Item
                  label="Titre"
                  name="data1"
                  hasFeedback
                  style={{
                    width: 300,
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a title"
                    style={{
                      width: 260,
                    }}
                  >
                    <Option value="Médecine du travail">
                      Médecine du Travail
                    </Option>
                    <Option value="SST">Secouriste SST</Option>
                  </Select>
                </Form.Item>
              ) : (
                <></>
              )}
              {typeform === 'Offshore' ? (
                <Form.Item
                  label="Titre"
                  name="data1"
                  hasFeedback
                  style={{
                    width: 300,
                  }}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Select
                    placeholder="Select a title"
                    style={{
                      width: 260,
                    }}
                  >
                    <Option value="BOSIET HUET">BOSIET (HUET)</Option>
                    <Option value="CA-ESB">CA-ESB</Option>
                    <Option value="Escape Chute">Escape Chute</Option>
                    <Option value="Module 7 HSE TEPC">Module 7 HSE TEPC</Option>
                    <Option value="Module 8 H2S">Module 8 H2S</Option>
                  </Select>
                </Form.Item>
              ) : (
                <></>
              )}
              {typeform === 'Spécifique site' ? (
                <>
                  <Form.Item
                    label="Client"
                    name="data1"
                    hasFeedback
                    style={{
                      width: 300,
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select a name"
                      style={{ width: 260 }}
                    >
                      {clientslist.map((val, index) => (
                        <Select.Option
                          key={index}
                          value={val.nom + ' ' + val.lieu}
                        >
                          {val.nom + ' ' + val.lieu}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <></>
              )}
              {typeform === 'Visa Multiple' || typeform === 'Visa Unique' ? (
                <>
                  <Form.Item
                    label="Pays"
                    name="data1"
                    hasFeedback
                    style={{
                      width: 260,
                    }}
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select a country"
                      style={{
                        width: 250,
                      }}
                    >
                      <Option value="Angola">Angola</Option>
                      <Option value="Burkina Faso">Burkina Faso</Option>
                      <Option value="Gabon">Gabon</Option>
                      <Option value="Mali">Mali</Option>
                      <Option value="Mauritanie">Mauritanie</Option>
                      <Option value="Togo">Togo</Option>
                      <Option value="Guinee-Bissau">Guinée-Bissau</Option>
                      <Option value="Congo Brazzaville">
                        Congo Brazzaville
                      </Option>
                      <Option value="Congo RDC">Congo RDC</Option>
                    </Select>
                  </Form.Item>
                </>
              ) : (
                <></>
              )}
              {typeform === 'Formation' ||
              typeform === 'Spécifique site' ||
              typeform === 'Attestation Santé' ||
              typeform === 'Habilitation Electrique' ||
              typeform === 'Visa Unique' ||
              typeform === 'Visa Multiple' ||
              typeform === 'Offshore' ||
              typeform === 'Autorisation de conduite' ? (
                <Form.Item
                  label="Fin de Validité"
                  name="valid"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <DatePicker format={dateFormatList} />
                </Form.Item>
              ) : (
                <></>
              )}

              <Form.Item
                style={{
                  margin: 1,
                }}
              >
                <Space wrap>
                  <Button
                    style={{ backgroundColor: '#0f1e5a' }}
                    type="primary"
                    htmlType="submit"
                  >
                    Ajouter
                  </Button>
                </Space>
              </Form.Item>
              <div className="absolute top-3 right-3">
                <Form.Item>
                  <Button
                    style={{ backgroundColor: 'white' }}
                    htmlType="button"
                    size="small"
                    onClick={onReset}
                  >
                    X
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Form>
  );
}
