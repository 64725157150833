import { Button, DatePicker, Form, Input, Select, Space } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import '../../../src/Popup.css';
import NotyfContext from '../../Context/NotyfContext';
import { Axios } from '../../config';
import CardRetour from './CardRetour';

export default function CardMovStock({
  btupdateLoanId,
  valitem,
  setLoanlist,
  setUpdateCde,
  user,
  onReset,
  loanlist,
  onFinishFailed,
}) {
  const [dateprev, setDatePrev] = useState('');
  const daterecep = '';

  const { RangePicker } = DatePicker;
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
  const onDatePrevChange = (date, dateString) => {
    setDatePrev(dateString);
  };
  const notyf = useContext(NotyfContext);
  const [loanslist, setLoanslist] = useState([]);

  useEffect(() => {
    loanlist.filter((value) => {
      if (valitem.id === value.outils_id) {
        setLoanslist(value);
      }
      return false;
    });
  });

  // useEffect (() => {
  // }, [loanlist])

  async function btinsertMov(val) {
    await Axios.post('/api/insertloan', {
      outils_id: valitem.id,
      users_id: val.userid,
      create_time: dateprev.toString(),
      obs: val.obs,
      recep: daterecep.toString(),
    }).then((response) => {
      if (response.data.err) {
        notyf.error(response.data.err.code);
      } else {
        setLoanlist([
          ...loanlist,
          {
            outils_id: val.id,
            users_id: val.userid,
            create_time: dateprev.toString(),
            obs: val.obs,
            recep: daterecep.toString(),
          },
        ]);
        setUpdateCde('1');
        notyf.success(`Objet sorti du Stock`);
        form.resetFields();
      }
    });
  }

  const [form] = Form.useForm();

  return (
    <div className="popup">
      <div className="popup-inner">
        <div className="card01">
          <h4>AJOUTER UN MOUVEMENT</h4>
          <table className="card11">
            <thead className="flex">
              <tr>
                <th>
                  <h3>Emprunté par</h3>
                </th>
              </tr>
              <tr>
                <th>
                  <h3>Départ / Retour</h3>
                </th>
              </tr>
              <tr>
                <th>
                  <h3>Commentaires</h3>
                </th>
              </tr>
              <tr>
                <th>
                  <h3>Retour au stock le</h3>
                </th>
              </tr>
              <tr>
                <th>
                  <h3>.</h3>
                </th>
              </tr>
            </thead>
          </table>
          {loanlist
            .filter((value) => {
              if (valitem.id === value.outils_id) {
                return value;
              }
              return false;
            })
            .map((value, i, row) => {
              if (i + 1 === row.length) {
                return (
                  <table className="card11" key={value.id}>
                    <tbody className="flex bg c">
                      <tr>
                        <td>
                          <h4>{value.users_id}</h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{value.create_time}</h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{value.obs}</h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h4>{value.recep}</h4>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h3>.</h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              }
              return null;
            })}
          {valitem.userid === 'STOCK' ? (
            <div className="card11 flex">
              <Form
                name="mouvement"
                form={form}
                onFinish={btinsertMov}
                onFinishFailed={onFinishFailed}
              >
                <Space wrap>
                  <Form.Item
                    name="userid"
                    rules={[
                      {
                        required: true,
                        message: 'Please Select your Name',
                      },
                    ]}
                  >
                    <Select showSearch placeholder="Emprunté par">
                      {user.map((val, index) => (
                        <Select.Option key={index} value={val.id}>
                          {val.lastname}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    name="dateprev"
                    rules={[
                      {
                        required: true,
                        message: 'Please Select a Date',
                      },
                    ]}
                  >
                    <RangePicker
                      format={dateFormatList}
                      type="text"
                      name="dateprev"
                      onChange={onDatePrevChange}
                    />
                  </Form.Item>
                  <Form.Item
                    name="obs"
                    rules={[
                      {
                        required: true,
                        message: 'Please input a Customer',
                      },
                    ]}
                  >
                    <Input
                      placeholder="Nom + Lieu Chantier"
                      type="text"
                      maxLength={20}
                      name="obs"
                    />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      type="primary"
                      size="small"
                      ghost
                      htmlType="submit"
                      onClick={() => {
                        btupdateLoanId(
                          valitem.id,
                          (valitem.loanid = 0),
                          (valitem.userid = 25)
                        );
                      }}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Space>
              </Form>
            </div>
          ) : (
            <></>
          )}
          <div className="close-btn">
            <Form.Item>
              <Button htmlType="button" size="small" onClick={onReset}>
                X
              </Button>
            </Form.Item>
          </div>
        </div>
        {valitem.userid === 'HORS-ST' ? (
          <CardRetour
            btupdateLoanId={btupdateLoanId}
            onFinishFailed={onFinishFailed}
            onReset={onReset}
            valitem={valitem}
            loanslist={loanslist}
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
