import { Button, Form, Select } from 'antd';
import React from 'react';
import '../../../node_modules/antd/dist/antd.min.css';
import '../../../src/style.css';
import '../../../src/style_tailwind.css';

export default function CardReinitMdp({
  onFinish,
  onFinishFailed,
  setButtonPopup,
  userslist,
}) {
  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <Form
      name="mdp"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <main className="flex justify-center justify-items-center absolute w-full  h-screen top-0 left-0">
        <div className="relative max-w-6xl">
          <div className="m-2 border bg-blue-spie">
            <h4 className="text-white text-base text-center mt-2">
              Réinitialiser MDP
            </h4>
            <div className="flex flex-col  bg-white m-1">
              <Form.Item
                name="useridmdp"
                label="Utilisateur"
                style={{
                  margin: '0.5rem',
                }}
                rules={[{ required: true }]}
              >
                <Select
                  showSearch
                  placeholder="Select a name"
                  // onChange={onSearch}
                  style={{ width: 200, marginRight: 10 }}
                >
                  {userslist.map((val, index) => (
                    <Select.Option key={index} value={val.id}>
                      {val.lastname}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                style={{
                  margin: '0.5rem',
                }}
              >
                <Button
                  style={{ backgroundColor: '#0f1e5a' }}
                  type="primary"
                  htmlType="submit"
                >
                  Réinitialiser
                </Button>
              </Form.Item>
              <div className="absolute top-3 right-3">
                <Form.Item
                  style={{
                    backgroundColor: '',
                  }}
                >
                  <Button
                    style={{ backgroundColor: 'white' }}
                    htmlType="button"
                    size="small"
                    onClick={onReset}
                  >
                    X
                  </Button>
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </main>
    </Form>
  );
}
