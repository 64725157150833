import { Button, Form } from 'antd';
import React from 'react';
import '../../../src/Popup.css';

import '../../../src/style_tailwind.css';

import moment from 'moment';

function CardInfo({ valitem, onReset, loanlist }) {
  var valid = valitem.rev;
  var dateLimit = moment(valid);
  return (
    <div className="flex flex-col h-full relative m-2 border-2 max-w-6xl border-blue-spie rounded-2xl bg-gray-200">
      <div className="flex fixed top-60 m-2 border-2 border-blue-spie bg-gray-200 bg-opacity-80 rounded-2xl shadow-2xl">
        <div className="flex flex-col justify-start p-2 rounded-xl">
          <div className="w-full flex justify-start">
            <div className="w-60 border rounded-2xl p-2 m-2 bg-white shadow-lg">
              <h1 className="text-xl text-blue-spie font-semibold">
                {valitem.spie}
              </h1>
            </div>
            <Form.Item className="m-0">
              <Button
                style={{ backgroundColor: 'white' }}
                htmlType="button"
                size="large"
                type="dashed"
                shape="circle"
                onClick={onReset}
              >
                x
              </Button>
            </Form.Item>
          </div>
          <div className="flex flex-row border rounded-2xl w-72 p-2 m-2 bg-white shadow-lg">
            <div className="w-full">
              <h3 className="px-2 py-2 font-semibold">Marque:</h3>
              <h3 className="px-2 py-2 font-semibold">Type:</h3>
              <h3 className="px-2 py-2 font-semibold">Désignation:</h3>
              <h3 className="px-2 py-2 font-semibold">Observations:</h3>
              <h3 className="px-2 py-2 font-semibold">N° de série:</h3>
              <h3 className="px-2 py-2 font-semibold">Révision:</h3>
              <h3 className="px-2 py-2 font-semibold">Attribué à:</h3>
            </div>
            <div className="w-full">
              <h2 className="px-2 py-2">{valitem.num ?? '-'}</h2>
              <h3 className="px-2 py-2">{valitem.ref ?? '-'}</h3>
              <h3 className="px-2 py-2">{valitem.design ?? '-'}</h3>
              <h3 className="px-2 py-2">{valitem.obs ?? '-'}</h3>
              <h3 className="px-2 py-2">{valitem.sn ?? '-'}</h3>
              <span>
                {(
                  <h3 className="px-2 py-2">
                    {dateLimit.format('DD-MM-YYYY')}
                  </h3>
                ) ?? '-'}
              </span>
              <h3 className="px-2 py-2">{valitem.userid ?? '-'}</h3>
            </div>
          </div>
          <div className="border rounded-2xl p-2 m-2 w-72 bg-white shadow-lg">
            <h2 className="text-center p-2 font-semibold text-base">
              MOUVEMENTS STOCK
            </h2>
            {loanlist
              .filter((value) => {
                if (valitem.id === value.outils_id) {
                  return value;
                }
                return false;
              })
              .map((value, i, row) => {
                if (i + 1 === row.length) {
                  return (
                    <div
                      className="flex bg-yellow-100 rounded-2xl p-2"
                      key={value.id}
                    >
                      <div className="w-full">
                        <h3 className="px-2 py-2 font-semibold">Pris par:</h3>
                        <h3 className="px-2 py-2 font-semibold">Chantier:</h3>
                        <h3 className="px-2 py-2 font-semibold">Date:</h3>
                        <h3 className="px-2 py-2 font-semibold">Retour le:</h3>
                      </div>
                      <div className="w-full">
                        <h3 className="px-2 py-2">{value.users_id}</h3>
                        <h3 className="px-2 py-2">{value.obs}</h3>
                        <h3 className="px-2 py-2">{value.create_time}</h3>
                        <h3 className="px-2 py-2">
                          {value.recep === '' ? (
                            <h3>(Attente retour)</h3>
                          ) : (
                            <h3>{value.recep}</h3>
                          )}
                        </h3>
                      </div>
                    </div>
                  );
                }
                return null;
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CardInfo;
