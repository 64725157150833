import { UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';

export default function CardAjoutSupplier({
  onFinish,
  onFinishFailed,
  setButtonPopup,
}) {
  const onReset = () => {
    form.resetFields();
    setButtonPopup(false);
  };

  const [form] = Form.useForm();

  return (
    <main className="flex justify-center justify-items-center absolute w-full  h-screen top-16 left-0">
      <div className="relative max-w-6xl">
        <div className="m-2 border bg-blue-spie">
          <h4 className="flex text-white text-base text-center mt-2 w-96">
            AJOUTER UN FOURNISSEUR
          </h4>
          <div className="flex flex-col bg-white m-1 p-2">
            <Form
              name="basic"
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              form={form}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Fournisseur"
                name="supplier_name"
                rules={[{ required: true }]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Fournisseur"
                  value="supplier_name"
                  maxLength={20}
                ></Input>
              </Form.Item>
              <div className="flex justify-center justify-items-center">
                <Form.Item
                  style={{
                    margin: 1,
                  }}
                >
                  <Button
                    style={{ backgroundColor: '#0f1e5a' }}
                    type="primary"
                    htmlType="submit"
                  >
                    Ajouter
                  </Button>
                </Form.Item>
                <Form.Item
                  style={{
                    margin: 1,
                  }}
                >
                  <Button
                    style={{ backgroundColor: '#0f1e5a' }}
                    htmlType="button"
                    type="primary"
                    onClick={onReset}
                  >
                    Reset
                  </Button>
                </Form.Item>
              </div>
            </Form>
          </div>
          <div className="absolute top-3 right-3">
            <Form.Item>
              <Button
                style={{ backgroundColor: 'white' }}
                htmlType="button"
                size="small"
                onClick={onReset}
              >
                X
              </Button>
            </Form.Item>
          </div>
        </div>
      </div>
    </main>
  );
}
