import {
  Outlet,
  RouterProvider,
  createBrowserRouter
} from 'react-router-dom';
import Admin from './components/Admin/Admin';
import Clearances from './components/Clearances/Clearances';
import Codes from './components/Codes/Codes';
import Header from './components/Header/Header';
import Logged from './components/Header/Logged';
import ErrorPage from './components/Home/ErrorPage';
import Home from './components/Home/Home';
import Password from './components/Password/Password';
import Profile from './components/Profile/Profile';
import Site from './components/Site/Site';
import Tools from './components/Tools/Tools';

const auth = (localStorage.getItem('auth'))

const router = createBrowserRouter([
  { path: '/', element: <Root />, errorElement: <ErrorPage />,
    children: [
      { path: '/admin', element: <Admin /> },
      { path: '/logged', element: <Logged /> },
      { path: '/password', element: <Password /> },
      { path: '/profile', element: <Profile /> },
      { path: '/site', element: <Site /> },
      { path: '/tools', element: <Tools /> },
      { path: '/codes', element: <Codes /> },
      { path: '/clearances', element: <Clearances />}
    ]
  }
])

function Root () {
  return <>
  {auth === false ?<Home/>:<Header/>}
    <div><Outlet/></div>
  </>
}

export default function App() {
  return <RouterProvider router={router} />;
}
