import { Button, Form, Input, Space } from 'antd';
import React from 'react';
import '../../../src/Popup.css';

import { UserOutlined } from '@ant-design/icons';

export default function CardMdp({
  chgPassword,
  onFinishFailed,
  username,
  onReset,
}) {
  const [form] = Form.useForm();

  return (
    <Form
      name="current-password"
      style={{
        width: 280,
      }}
      form={form}
      onFinish={chgPassword}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <div className="flex justify-center justify-items-center absolute w-full  h-screen top-1 left-0">
        <div className="relative max-w-6xl">
          <div className="m-2 border bg-blue-spie">
            <h4 className="text-white text-base text-center mt-2 w-96">
              CHANGER MOT DE PASSE
            </h4>
            <div className="flex flex-col bg-white m-1 p-2">
              <Form.Item label="Nom" name="lastname">
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder={username}
                  value={username}
                  disabled
                />
              </Form.Item>
              <Form.Item
                label="Mot de passe"
                name="newpassword"
                rules={[
                  {
                    required: true,
                    message: 'Please input your new-password!',
                  },
                ]}
              >
                <Input.Password placeholder="new password" type="password" />
              </Form.Item>
              <Form.Item
                style={{
                  margin: 1,
                }}
              >
                <Space wrap>
                  <Button
                    style={{ backgroundColor: '#0f1e5a' }}
                    type="primary"
                    htmlType="submit"
                  >
                    Modifier
                  </Button>
                </Space>
              </Form.Item>
            </div>
            <div className="absolute top-3 right-3">
              <Form.Item>
                <Button
                  style={{ backgroundColor: 'white' }}
                  htmlType="button"
                  size="small"
                  onClick={onReset}
                >
                  X
                </Button>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}
