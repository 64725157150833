import { Button, DatePicker, Form, Space } from 'antd';
import React from 'react';
import '../../../src/Popup.css';

function PopupDate({ onFinishFailed, onReset, onFinish }) {
  const [form] = Form.useForm();
  const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

  return (
    <Form
      name="basic"
      style={{
        width: 320,
      }}
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <div className="flex justify-center justify-items-center absolute w-full  h-screen top-1 left-0">
        <div className="relative max-w-6xl">
          <div className="m-2 border bg-blue-spie">
            <h4 className="flex text-white text-base text-center mt-2 w-96">
              MODIFIER DATE DE VALIDITE
            </h4>
            <div className="flex flex-col bg-white m-1 p-2">
              <Form.Item
                label="Fin de Validité"
                name="valid"
                hasFeedback
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker format={dateFormatList} />
              </Form.Item>
              <Form.Item>
                <Space wrap>
                  <Button
                    style={{ backgroundColor: '#0f1e5a' }}
                    type="primary"
                    htmlType="submit"
                  >
                    Modifier
                  </Button>
                </Space>
              </Form.Item>
            </div>
            <div className="absolute top-3 right-3">
              <Form.Item>
                <Space>
                  <Button
                    style={{ backgroundColor: 'white' }}
                    htmlType="button"
                    size="small"
                    onClick={onReset}
                  >
                    X
                  </Button>
                </Space>
              </Form.Item>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
}
export default PopupDate;
