import { Select } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import '../../../src/style.css';
import { Axios } from '../../config';
import { useAppStore } from '../../store';
import LoadAnim from '../LoadAnim';

export default function Employed() {
  const loading = useAppStore.use.loading();
  const updateLoading = useAppStore.use.updateLoading();
  const [userslist, setUserslist] = useState([]);

  useEffect(() => {
    updateLoading(false);
  });

  useEffect(
    (notyf) => {
      Axios.get('/api/get_users').then((response) => {
        if (response.data.err) {
          notyf.error(response.data.err.code);
        } else {
          setUserslist(response.data);
        }
      });

      updateLoading(true);
    },
    [updateLoading]
  );

  // const layout = {
  //   labelCol: {
  //     span: 8,
  //   },
  //   wrapperCol: {
  //     span: 32,
  //   },
  // };

  return (
    <div className="flex items-center justify-center m-2 border-2">
      <div className="flex flex-col justify-start bg-white p-2">
        <table className="table-auto border separate border-blue-spie">
          <caption className="caption-top  bg-blue-spie flex p-2">
            <h4 className="text-white align-middle text-base">
              <b>LISTE DES EMPLOYES</b>
            </h4>
          </caption>
          {loading === true ? (
            <LoadAnim size="large" />
          ) : (
            <>
              {userslist
                .sort((a, b) => (a.lastname > b.lastname ? -1 : 1))
                .map((val, index) => (
                  <Select.Option key={index} value={val.lastname}>
                    {val.lastname}
                  </Select.Option>
                ))}
            </>
          )}
          <thead className="flex">
            <tr className="border-red-900 text-center">
              <th className="w-5 text-blue-spie text-base text-center">ID</th>
              <th className="text-blue-spie text-base">NOM</th>
              <th className="text-blue-spie text-base">PRENOM</th>
              <th className="text-blue-spie text-base">COURRIEL</th>
              {/* <th className="text-blue-spie text-base">.</th> */}
              <th className="text-blue-spie text-base">NIVEAU ACCES</th>
              <th className="text-blue-spie text-base">DATE CREATION</th>
            </tr>
          </thead>
          {userslist
            .sort((a, b) => (a.userid > b.userid ? 1 : -1))
            .map((val, index) => {
              var datecreation = moment(val.create_time);
              return (
                <tbody className="flex" key={index}>
                  <tr className="border-t-2 text-center">
                    <td className="w-5">{val.id}</td>
                    <td className="">{val.lastname}</td>
                    <td className="">{val.firstname}</td>
                    <td className="">{val.email}</td>
                    {/* <td className="">.</td> */}
                    <td className="">{val.access}</td>
                    <td className="">{datecreation.format('DD-MM-YYYY')}</td>
                  </tr>
                </tbody>
              );
            })}
        </table>
      </div>
    </div>
  );
}
