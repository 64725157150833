import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

const createSelectors = (_store) => {
  let store = _store;
  store.use = {};
  for (let k of Object.keys(store.getState())) {
    store.use[k] = () => store((s) => s[k]);
  }
  return store;
};
export const useAppStore = createSelectors(
  create(
    devtools((set) => ({
      access: 0,
      updateAccess(access) {
        set({ access: access });
      },
      auth: false,
      updateAuth(auth) {
        set({ auth: auth });
      },
      loading: false,
      updateLoading(Loading) {
        set({ Loading: Loading });
      },
    }))
  )
);
